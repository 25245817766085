<template>
  <div class="container-fluid py-5">
    <div class="row">

      <div class="container mb-2">
        <div class="col-12">
          <div class="row bg-white rounded-lg">
            <div class="col-auto py-2 pr-0">
              <b-form-datepicker hide-header
                                 @input="loadRooms"
                                 v-model="date"
                                 :locale="$i18n.locale"
                                 :min="minDate"
                                 :max="maxDate"
                                 :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"/>
            </div>
            <div class="col py-2">
              <b-button variant="yellow" @click="loadRooms">{{$t("search")}}</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100"></div>
      <div class="container py-5 text-center text-white" v-if="state==='loading'">
        <div-loader />
      </div>
      <div class="container py-5 text-center" v-else-if="state==='error'">
        <error-div :message="stateMessage" />
      </div>
      <div class="container" v-else>
        <b-overlay :show="loading">
          <div class="row">
            <div class="col-12 mb-3" v-for="(room,index) in rooms" :key="'roomsWrap'+index">
              <div class="col-12 rounded-lg bg-white">
                <div class="row">
                  <div class="col-12 col-sm-4 py-2 border-right">
                    <img :src="room.imageUrl" class="w-100" />
                  </div>
                  <div class="col-12 col-sm-8 py-3">
                    <h5 class="textColor">{{room.number}}</h5>
                    <div class="w-100"></div>
                    <div class="w-100 mb-2">
                      {{room.minPerson}} - {{room.maxPerson}} {{$t("persons")}}
                    </div>

                    <room-blocks :is-day-closed="isDayClosed" :slot-timing="slotTiming"
                                 :extra-price="0"
                                 :selected="false"
                                 :date="date"
                                 :room="room"
                                 :current-time="currentTime"
                                 :selected-slot="selectedSlot"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorDiv from "@/components/Template/ErrorDiv";
import DivLoader from "@/components/Template/DivLoader";
import RoomBlocks from "@/components/RoomBooking/RoomBlocks";
export default {
  name: "RoomBooking",
  components: {RoomBlocks, DivLoader, ErrorDiv},
  created() {
    this.loadRooms();
  },
  methods : {
    roomClass: function (room,slot) {
      return {
        "roomSlotClosed" : (room.open===0 || slot===0),
        "roomSlotOpenedNoHover " : (room.open===1 && slot===1),
      }
    },
    loadRooms() {
      this.rooms = [];
      this.state = "loading";
      this.$http.get("front/rooms",{
        params : {
          date : this.date
        }
      }).then(response=>{
        if(response.data.error===undefined) {
          this.state = "error";
          this.stateMessage = this.$t("errors.unableToLoadRooms");
        } else if(response.data.error) {
          this.state = "error";
          this.stateMessage = response.data.message;
        } else {
          this.selectedPerson = 0;
          this.selectedRoom = {};
          this.selectedSlot = [];
          this.selectedRoomId = "0";
          this.rooms = response.data.list;
          this.isDayClosed = response.data.isDayClosed;
          this.currentTime = response.data.time;
          this.slotTiming = response.data.slotTiming;
          this.state = "success";
        }
      }).catch(error=>{
        this.state = "error";
        this.stateMessage = error.toString();
      });
    }
  },
  data() {
    return {
      form : {
        agree : "0"
      },
      isDayClosed : false,
      loading : false,
      haveError : false,
      errorMessage : "",
      selectedPerson : 5,
      selectedRoomId : "",
      selectedSlot : [],
      selectedRoom : {},
      slotTiming : {},
      state : 'loading',
      stateMessage : "",
      currentTime : 0,
      rooms : [],
      date : this.$moment().format("YYYY-MM-DD"),
      minDate : new Date(),
      maxDate : this.$moment().add(1,'year').toDate(),
    }
  }
}
</script>

<style scoped>

</style>
